import { CLIENT_ADMIN } from "@ems/constants";
import Routes from "./Routes.vue";
import {
  SETTING_INFORMATION,
  DASHBOARD,
  USER,
  RENEWABLE_OPTIONS,
  FACILITIES_MONITORING,
  SUPPLY_CHAIN_MANAGEMENT,
  COMPANY_ACCOUNT_MANAGEMENT,
  RENEWABLE_ENERGY_TARGET_MANAGEMENT,
  BUSINESS_ACCOUNT_INFORMATION,
} from "@ems/constants";

const view =
  (name: string) =>
  (PERMISSION = CLIENT_ADMIN) =>
    import(
      /* webpackChunkName: "[request]" */ `@ems/pages/${PERMISSION}/${name}.vue`
    );
export default [
  {
    path: DASHBOARD.path,
    name: DASHBOARD.name,
    component: Routes,
    icon: "dashboard",
    children: [
      {
        path: DASHBOARD.path,
        name: DASHBOARD.name,
        component: view("Dashboard/index"),
      },
      {
        path: DASHBOARD.SUSTAINABILITY_ACHIEVEMENTS.path,
        name: DASHBOARD.SUSTAINABILITY_ACHIEVEMENTS.name,
        component: view("Dashboard/SA"),
      },
      {
        path: DASHBOARD.PERFORMANCE_OVERVIEW.path,
        name: DASHBOARD.PERFORMANCE_OVERVIEW.name,
        component: view("Dashboard/PO"),
      },
    ],
  },
  {
    path: BUSINESS_ACCOUNT_INFORMATION.path,
    name: BUSINESS_ACCOUNT_INFORMATION.name,
    component: Routes,
    icon: "account_information",
    children: [
      {
        path: "",
        name: BUSINESS_ACCOUNT_INFORMATION.name,
        component: view("BusinessAccountInformation/index"),
      },
      // {
      //   path: BUSINESS_ACCOUNT_INFORMATION.path,
      //   name: BUSINESS_ACCOUNT_INFORMATION.name,
      //   component: view("RenewableEnergyTargetManagement/PublishNewTarget"),
      // },
    ],
  },
  {
    path: RENEWABLE_ENERGY_TARGET_MANAGEMENT.path,
    name: RENEWABLE_ENERGY_TARGET_MANAGEMENT.name,
    component: Routes,
    icon: "fluent_target",
    children: [
      {
        path: "",
        name: RENEWABLE_ENERGY_TARGET_MANAGEMENT.name,
        component: view("RenewableEnergyTargetManagement/index"),
      },
      {
        path: RENEWABLE_ENERGY_TARGET_MANAGEMENT.PUBLISH_NEW_TARGET.path,
        name: RENEWABLE_ENERGY_TARGET_MANAGEMENT.PUBLISH_NEW_TARGET.name,
        component: view("RenewableEnergyTargetManagement/PublishNewTarget"),
      },
    ],
  },
  {
    path: FACILITIES_MONITORING.path,
    name: FACILITIES_MONITORING.name,
    component: Routes,
    icon: "building_house",
    children: [
      {
        path: "",
        name: FACILITIES_MONITORING.name,
        component: view("Dashboard/FM/index"),
      },
      {
        path: `${FACILITIES_MONITORING.FACILITIES_CREATE.path}`,
        name: FACILITIES_MONITORING.FACILITIES_CREATE.name,
        component: view("Dashboard/FM/CRUDFacility"),
      },
      {
        path: `${FACILITIES_MONITORING.FACILITIES_MONITORING_ID.path}`,
        name: "Edit Facility",
        component: view("Dashboard/FM/CRUDFacility"),
      },
    ],
  },
  {
    path: SETTING_INFORMATION.path,
    name: SETTING_INFORMATION.name,
    component: Routes,
    icon: "setting-info",
    children: [
      {
        path: "",
        name: SETTING_INFORMATION.name,
        component: view("SettingInformation/S2RP/index"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_1_AND_3.path,
        name: SETTING_INFORMATION.SCOPE_1_AND_3.name,
        component: view("SettingInformation/S13E/index"),
      },
      {
        path: SETTING_INFORMATION.SCOPE13E_INPUT_MANUAL.path,
        name: SETTING_INFORMATION.SCOPE13E_INPUT_MANUAL.name,
        component: view("SettingInformation/S13E/InputDataManual"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_2_INPUT_PPA.path,
        name: SETTING_INFORMATION.SCOPE_2_INPUT_PPA.name,
        component: view("SettingInformation/S2RP/InputPPA_RECs"),
      },
      {
        path: SETTING_INFORMATION.S2RP_INPUT_MANUAL.path,
        name: SETTING_INFORMATION.S2RP_INPUT_MANUAL.name,
        component: view("SettingInformation/S2RP/InputDataManual"),
      },
      {
        path: SETTING_INFORMATION.SCOPE_2_EMISSION_TARGETS.path,
        name: SETTING_INFORMATION.SCOPE_2_EMISSION_TARGETS.name,
        component: view("SettingInformation/CET"),
      },
      {
        path: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.path,
        name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.name,
        component: Routes,
        children: [
          {
            path: "",
            name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.name,
            component: view("SettingInformation/YGO/index"),
          },
        ],
      },
      {
        path: `${SETTING_INFORMATION.YOUR_GREEN_OPTIONS.path}/${SETTING_INFORMATION.YOUR_GREEN_OPTIONS.CONTACT_Us.path}`,
        name: SETTING_INFORMATION.YOUR_GREEN_OPTIONS.CONTACT_Us.name,
        component: view("SettingInformation/YGO/ContactUs"),
      },
      {
        path: SETTING_INFORMATION.SETTING_BASELINE.path,
        name: SETTING_INFORMATION.SETTING_BASELINE.name,
        component: view("SettingInformation/SettingBaseline/index"),
      },
    ],
  },
  {
    path: RENEWABLE_OPTIONS.path,
    name: RENEWABLE_OPTIONS.name,
    component: Routes,
    icon: "Renewable",
    children: [
      {
        path: "",
        name: RENEWABLE_OPTIONS.name,
        component: view("RenewableOptions/index"),
      },
      {
        path: RENEWABLE_OPTIONS.BUY_RECS.path,
        name: RENEWABLE_OPTIONS.BUY_RECS.name,
        component: view("RenewableOptions/BuyRECs"),
      },
      {
        path: RENEWABLE_OPTIONS.RENEWABLE_OPTION_ID.path,
        name: RENEWABLE_OPTIONS.RENEWABLE_OPTION_ID.name,
        component: view("RenewableOptions/RenewableOptionDetail"),
      },
    ],
  },
  {
    path: USER.USER_OVERVIEW.path,
    name: USER.USER_OVERVIEW.name,
    component: Routes,
    icon: "user_management",
    children: [
      {
        path: "",
        name: USER.USER_OVERVIEW.name,
        component: view("User/index"),
      },
      {
        path: USER.USER_CREATE.path,
        name: USER.USER_CREATE.name,
        component: view("User/CRUDUser"),
      },
      {
        path: ":UserId/edit",
        name: "Edit User",
        component: view("User/DetailEditUser"),
      },
    ],
  },
  {
    path: SUPPLY_CHAIN_MANAGEMENT.path,
    name: SUPPLY_CHAIN_MANAGEMENT.name,
    component: Routes,
    icon: "Vector",
    children: [
      {
        path: "",
        name: SUPPLY_CHAIN_MANAGEMENT.name,
        component: view("SupplyChainManagement/index"),
      },
      {
        path: SUPPLY_CHAIN_MANAGEMENT.INVITE_PARTNER.path,
        name: SUPPLY_CHAIN_MANAGEMENT.INVITE_PARTNER.name,
        component: view("SupplyChainManagement/InvitePartner"),
      },
    ],
  },
  {
    path: COMPANY_ACCOUNT_MANAGEMENT.path,
    name: COMPANY_ACCOUNT_MANAGEMENT.name,
    component: Routes,
    icon: "Group",
    children: [
      {
        path: "",
        name: COMPANY_ACCOUNT_MANAGEMENT.name,
        component: view("CompanyAccountManagement/index"),
      },
    ],
  },
];
