import { OPS_ADMIN } from "@ems/constants";
import { ACCOUNT_REGISTRATION, DASHBOARD } from "@ems/constants/router_admin";
import Routes from "./Routes.vue";

const view =
  (name: string) =>
  (PERMISSION = OPS_ADMIN) =>
    import(
      /* webpackChunkName: "[request]" */ `@ems/pages/${PERMISSION}/${name}.vue`
    );
export default [
  {
    path: DASHBOARD.path,
    name: DASHBOARD.name,
    component: Routes,
    icon: "dashboard",
    children: [
      {
        path: DASHBOARD.path,
        name: DASHBOARD.name,
        component: view("DashBoard/index"),
      },
    ],
  },
  {
    path: ACCOUNT_REGISTRATION.path,
    name: ACCOUNT_REGISTRATION.name,
    component: Routes,
    icon: "user_management",
    children: [
      {
        path: "",
        name: ACCOUNT_REGISTRATION.name,
        component: view("AccountRegistration/index"),
      },
    ],
  },
];
